import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import React, { useEffect, useState } from "react"

const HEADING_TO_DISPLAY = ["h1", "h2", "h3", "h4", "h5", "h6"]
const SCROLL_MARGIN_TOP = 80

const useStore = createStore({
    headings: [],
})

export function withTableOfContent(Component): ComponentType {
    return (props) => {
        const [store] = useStore()
        const [activeSection, setActiveSection] = useState("")

        const scrollToId = (id) => {
            const element = document.getElementById(id)
            if (element) {
                element.scrollIntoView({ behavior: "smooth" })
            }
        }

        useEffect(() => {
            const handleScroll = () => {
                const sections = store.headings.map((heading) =>
                    document.getElementById(heading.id)
                )
                const scrollPosition = window.scrollY - 160

                for (let i = sections.length - 1; i >= 0; i--) {
                    if (sections[i].offsetTop <= scrollPosition) {
                        setActiveSection(store.headings[i].id)
                        break
                    }
                }
            }

            window.addEventListener("scroll", handleScroll)
            return () => {
                window.removeEventListener("scroll", handleScroll)
            }
        }, [store.headings])

        return (
            <>
                {store.headings.length > 0 ? (
                    store.headings
                        .sort((a, b) => a.originalIndex - b.originalIndex)
                        .filter(({ type }) => HEADING_TO_DISPLAY.includes(type))
                        .map(({ id, heading, type }) => (
                            <Component
                                key={id}
                                {...props}
                                variant={
                                    activeSection === id
                                        ? `${type}-active`
                                        : type
                                }
                                title={heading}
                                onClick={() => scrollToId(id)}
                            />
                        ))
                ) : (
                    <Component {...props} />
                )}
            </>
        )
    }
}

export function withContent(Component): ComponentType {
    return (props: any) => {
        const [store, setStore] = useStore()
        const [newProps, setNewProps] = useState(props)

        useEffect(() => {
            const formatHeadings = () => {
                const newChildren = []
                React.Children.forEach(
                    props.children.props.children,
                    (item, index) => {
                        const children = item.props.children
                        if (HEADING_TO_DISPLAY.includes(item.type)) {
                            const { formattedId } = formatHeading(
                                children,
                                index
                            )

                            // Create a new object for the modified item
                            const newItem = {
                                ...item,
                                props: {
                                    ...item.props,
                                    id: formattedId,
                                },
                            }

                            newChildren.push(newItem)
                        } else {
                            newChildren.push(item)
                        }
                    }
                )

                // Return a new props object with the modified children
                return {
                    ...props,
                    children: {
                        ...props.children,
                        props: {
                            ...props.children.props,
                            children: newChildren,
                        },
                    },
                }
            }

            setNewProps(formatHeadings())
        }, [props])

        useEffect(() => {
            setStore({
                headings: formatTableOfContent(
                    newProps.children.props.children
                ),
            })
        }, [newProps.children.props.children])

        return (
            <>
                <style>{`.framer-text {
                    scroll-margin-top: ${SCROLL_MARGIN_TOP}px;
                }`}</style>
                <Component {...newProps} />
            </>
        )
    }
}

const formatTableOfContent = (children) => {
    const result = []

    React.Children.forEach(children, (item, index) => {
        if (HEADING_TO_DISPLAY.includes(item.type)) {
            const children = item.props.children
            const { formattedId, formattedHeading } = formatHeading(
                children,
                index
            )
            result.push({
                id: formattedId,
                heading: formattedHeading,
                type: item.type,
                originalIndex: index,
            })
        }
    })

    return result
}

const formatHeading = (children, index) => {
    let formattedHeading = ""
    let formattedId = ""

    if (typeof children === "string") {
        formattedHeading = children
    } else if (Array.isArray(children)) {
        formattedHeading = children
            .map((item) => {
                if (typeof item === "string") return item.trim()
                if (item.type === "br") return ""
                return item.props.children.trim()
            })
            .join(" ")
    } else if (typeof children === "object") {
        if (typeof children.props.children === "string") {
            formattedHeading = children.props.children
        }
    } else {
        formattedHeading = index.toString()
    }

    formattedId = formattedHeading
        .trim()
        .toLowerCase()
        .replace(/[^a-z]+/g, "-")
        .replace(/^-+|-+$/g, "")

    return { formattedId, formattedHeading }
}
